import PropTypes from 'prop-types';

import { Book, Options } from '../Icons';
import './StickyUiBottom.scss';

const StickyUiBottom = ({ links }) => (
  <div className="outline__links">
    <div className="outline__links-wrap">
      <ul className="link-list last-mb0">
        {links.map(({ label, link, ico }) => (
          <li key={label}>
            <a
              key={link}
              href={link}
              aria-label={label}
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex_style">
                {ico === 'book' && <Book colour="0" />}
                {ico === 'options' && <Options colour="0" />}
                <span>{label}</span>
              </div>
            </a>
          </li>
        ))}
        <li>{'\u00A0'}</li>
      </ul>
    </div>
  </div>
);

StickyUiBottom.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
    })
  ).isRequired,
};

export default StickyUiBottom;
