import { createContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'debounce';

export const NotifierContext = createContext({
  message: null,
  setMessage: () => null,
});

const debouncedSetMessage = debounce((value, setMessage) => {
  setMessage(value);
}, 50);

export const NotifierProvider = ({ children }) => {
  const [message, setMessage] = useState(null);

  const setMessageCallback = useCallback(
    (value) => debouncedSetMessage(value, setMessage),
    []
  );

  return (
    <NotifierContext.Provider
      value={{
        message,
        setMessage: setMessageCallback,
      }}
    >
      {children}
    </NotifierContext.Provider>
  );
};

NotifierProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
