import PropTypes from 'prop-types';

import { Table } from '../../Common';
import plural from '../../Utils/plural';

const createTuitionPatterns = ({ tuitionPatterns }) => (
  <Table.Table>
    <Table.Headers>
      <Table.Header>Type</Table.Header>
      <Table.Header>Duration</Table.Header>
    </Table.Headers>
    <Table.Rows>
      {tuitionPatterns.map(
        ({
          tuitionPatternDescription,
          contactHours,
          frequencyValue,
          frequencyContent,
        }) => (
          <Table.Row key={tuitionPatternDescription}>
            <Table.RowValue>{tuitionPatternDescription}</Table.RowValue>
            <Table.RowValue>{`${frequencyValue} x ${contactHours} ${plural(
              contactHours,
              'hour'
            )} ${frequencyContent}`}</Table.RowValue>
          </Table.Row>
        )
      )}
    </Table.Rows>
  </Table.Table>
);

const TuitionPatterns = ({ tuitionPatterns }) =>
  tuitionPatterns.length > 0 && (
    <>
      <h2>Tuition patterns</h2>
      <p>
        The tuition pattern provides details of the types of classes and their
        duration. This is to be used as a guide only. Precise information is
        included in the unit outline.
      </p>
      <div>{createTuitionPatterns({ tuitionPatterns })}</div>
    </>
  );

TuitionPatterns.propTypes = {
  tuitionPatterns: PropTypes.arrayOf(
    PropTypes.shape({
      tuitionPatternDescription: PropTypes.string,
      contactHours: PropTypes.string,
      frequencyValue: PropTypes.string,
      frequencyContent: PropTypes.string,
    })
  ),
};

TuitionPatterns.defaultProps = {
  tuitionPatterns: [],
};

export default TuitionPatterns;
