/* eslint-disable import/prefer-default-export */

const resolveTypeCode = {
  E: 'Equivalents',
  A: 'Antirequisites',
  P: 'Prerequisites',
  C: 'Corequisites',
};

export { resolveTypeCode };
