/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

const resultProps = {
  index: PropTypes.number,
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  category: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export { resultProps };
