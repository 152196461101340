import { isValidElement, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

const AccordionGroup = ({ children }) => (
  <section
    className="block accordions"
    role="complementary"
    aria-label="Accordion"
    data-segment="all"
  >
    <div className="accordions__container">
      {Children.map(children, (child, index) =>
        isValidElement(child) && child.type.name === 'Accordion'
          ? cloneElement(child, {
              controls: `accordion-${index}`,
            })
          : child
      )}
    </div>
  </section>
);

AccordionGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AccordionGroup;
