import PropTypes from 'prop-types';

import Rule from './Rule';
import { rulePropType } from './propTypes';
import FakeTableHeader from './FakeTableHeader';
import { resolveTypeCode } from './constants';
import { resolveSubHeading } from './utils';
import PopUp from './PopUp';

const Requisite = ({ typeCode, rule, depth }) => {
  const requisiteGroupHeading = resolveTypeCode[typeCode];
  if (!requisiteGroupHeading) {
    return null;
  }
  const subHeadingContent = resolveSubHeading(
    typeCode,
    rule.operator,
    rule.operands
  );
  return (
    <>
      <h2 style={{ marginBottom: 0 }}>{requisiteGroupHeading}</h2>
      <PopUp typeCode={typeCode} />
      {subHeadingContent && <p>{subHeadingContent}</p>}
      <FakeTableHeader />
      <Rule typeCode={typeCode} rule={rule} maxDepth={depth} />
    </>
  );
};

Requisite.propTypes = {
  typeCode: PropTypes.string.isRequired,
  rule: rulePropType.isRequired,
  depth: PropTypes.number.isRequired,
};

const Requisites = ({ requisites }) =>
  requisites.map(({ typeCode, rule, depth }) => (
    <Requisite key={typeCode} typeCode={typeCode} rule={rule} depth={depth} />
  ));

Requisites.propTypes = {
  requisites: PropTypes.arrayOf(PropTypes.shape(Requisite.propTypes))
    .isRequired,
};

export default Requisites;
