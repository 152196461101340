import PropTypes from 'prop-types';

import './Select.scss';

const placeholder = 'Select location';

const generateIdFromValue = (value) => value.split(' ').join('-');

const Select = ({
  selected,
  onChange,
  options,
  showSelected,
  optionComponent,
  ariaLabel,
}) => {
  const filteredOptions = options.filter(
    (option) =>
      showSelected || (!showSelected && option.value !== selected.value)
  );
  return (
    <>
      <div
        role="listbox"
        className="nice-select select__options"
        tabIndex="0"
        aria-label={ariaLabel}
        aria-owns={filteredOptions
          .map(({ value }) => generateIdFromValue(value))
          .join(' ')}
      >
        <span>{selected.label}</span>
        <ul className="list">
          <li className={!showSelected ? 'option selected' : 'option'}>
            {placeholder}
          </li>
          {filteredOptions.map((option) => (
            <li
              id={generateIdFromValue(option.value)}
              role="option"
              aria-selected={option.value === selected.value ? 'true' : 'false'}
              key={option.value}
              data-value={option.value}
              className={
                selected.value === option.value ? 'option selected' : 'option'
              }
              onClick={() => onChange(option)}
            >
              {optionComponent(option)}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

Select.propTypes = {
  selected: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  showSelected: PropTypes.bool,
  optionComponent: PropTypes.func,
  ariaLabel: PropTypes.string,
};

Select.defaultProps = {
  showSelected: true,
  optionComponent: (option) => option.label,
  ariaLabel: null,
};

export default Select;
