import PropTypes from 'prop-types';

import { Accordion, renderLinks } from '../../Common';
import { some } from '../../Utils/tools';

const EntryAndCompletionDetails = ({
  entryRequirements,
  entryRequirementsSpecific,
  entryOrCompletionNotes,
  creditForRecognisedLearning,
  creditForRecognisedLearningNotes,
  intermediateAwards,
  awardPathwayGeneric,
  awardList,
  completionRequirements,
  durationAndAvailability,
}) => {
  const showIntermediateAwards = intermediateAwards.length > 0;
  const showEntryAndCompletionDetails = some(
    entryRequirements,
    entryRequirementsSpecific,
    entryOrCompletionNotes,
    creditForRecognisedLearning,
    creditForRecognisedLearningNotes,
    showIntermediateAwards,
    awardPathwayGeneric,
    awardList.length > 0,
    completionRequirements,
    durationAndAvailability
  );
  const showAwardPathway = some(awardPathwayGeneric, awardList.length > 0);
  return (
    showEntryAndCompletionDetails && (
      <Accordion
        label="Entry and completion details"
        controlId="entry-and-completion-details"
      >
        {entryRequirements && <p> {renderLinks(entryRequirements)}</p>}
        {entryRequirementsSpecific && (
          <p> {renderLinks(entryRequirementsSpecific)}</p>
        )}
        {entryOrCompletionNotes && (
          <p> {renderLinks(entryOrCompletionNotes)}</p>
        )}
        {some(
          creditForRecognisedLearning,
          creditForRecognisedLearningNotes
        ) && <h2 className="h3">Credit for recognised learning</h2>}
        {creditForRecognisedLearning && (
          <p> {renderLinks(creditForRecognisedLearning)}</p>
        )}
        {creditForRecognisedLearningNotes && (
          <p> {renderLinks(creditForRecognisedLearningNotes)}</p>
        )}
        {showIntermediateAwards && (
          <>
            <h2 className="h3">Intermediate awards</h2>
            <p>
              A student who has successfully completed the requirements of an
              approved intermediate award may apply for graduation in that award
              subject to approval of Head of School/Department. Fees apply.
              Intermediate awards approved for this course:
            </p>
            {intermediateAwards.map(
              ({ intermediateStudyPackCode, awardTitle }) => (
                <p key={intermediateStudyPackCode}>
                  {intermediateStudyPackCode} {awardTitle}
                </p>
              )
            )}
          </>
        )}
        {showAwardPathway && (
          <>
            <h2 className="h3">Pathway to further study</h2>
            <p> {renderLinks(awardPathwayGeneric)} </p>
            {awardList.map((award) => (
              <p key={award}>{award}</p>
            ))}
          </>
        )}
        {completionRequirements && (
          <>
            <h2 className="h3">Course completion requirements</h2>
            <p>{completionRequirements}</p>
          </>
        )}
        {durationAndAvailability && (
          <>
            <h2 className="h3">Duration and availability</h2>
            <p> {renderLinks(durationAndAvailability)} </p>
          </>
        )}
      </Accordion>
    )
  );
};

EntryAndCompletionDetails.propTypes = {
  entryRequirements: PropTypes.string,
  entryRequirementsSpecific: PropTypes.string,
  entryOrCompletionNotes: PropTypes.string,
  creditForRecognisedLearning: PropTypes.string,
  creditForRecognisedLearningNotes: PropTypes.string,
  intermediateAwards: PropTypes.arrayOf(
    PropTypes.shape({
      intermediateStudyPackCode: PropTypes.string.isRequired,
      awardTitle: PropTypes.string.isRequired,
    })
  ).isRequired,
  awardPathwayGeneric: PropTypes.string,
  awardList: PropTypes.arrayOf(PropTypes.string).isRequired,
  completionRequirements: PropTypes.string,
  durationAndAvailability: PropTypes.string,
};

EntryAndCompletionDetails.defaultProps = {
  entryRequirements: null,
  entryRequirementsSpecific: null,
  entryOrCompletionNotes: null,
  creditForRecognisedLearning: null,
  creditForRecognisedLearningNotes: null,
  awardPathwayGeneric: null,
  completionRequirements: null,
  durationAndAvailability: null,
};

export default EntryAndCompletionDetails;
