import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { debounce } from 'debounce';

import SearchInput from '../../Common/SearchInput';
import SearchButton from './SearchButton';
import { halfScrollOverride } from './constants';

import './PrimarySearch.scss';

const getSuggestions = debounce(async (value, callback, signal) => {
  try {
    const URL = `https://search.curtin.edu.au/s/suggest.json?collection=curtin-learning-offerings&partial_query=${encodeURIComponent(
      value
    )}`;
    const response = await fetch(URL, { signal });
    const data = await response.json();
    callback(
      [...new Set(data)].filter(
        (suggestion) => suggestion.toLowerCase() !== value.toLowerCase()
      )
    );
  } catch (error) {
    if (error.name !== 'AbortError') {
      console.error(error);
      callback([]);
    }
  }
}, 500);

const createSearchLink = (value) => {
  const params = new URLSearchParams({ search_text: value });
  return params.toString();
};

const AutoCompleteList = ({ value, hideOptions }) => {
  const [items, setItems] = useState([]);
  useEffect(() => {
    const controller = new AbortController();
    getSuggestions(value, setItems, controller.signal);
    return () => controller.abort();
  }, [value]);
  return (
    <ul className="autocomplete-list">
      {items.map((item) => (
        <li key={item}>
          <NavLink
            className="autocomplete-navlink"
            to={{
              pathname: '/',
              search: createSearchLink(item),
              state: halfScrollOverride,
            }}
            onClick={() => hideOptions()}
            onKeyDown={(e) => e.key === 'Escape' && hideOptions()}
          >
            {item}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

AutoCompleteList.propTypes = {
  value: PropTypes.string.isRequired,
  hideOptions: PropTypes.func.isRequired,
};

const PrimarySearch = ({
  value,
  onChange,
  onSubmit,
  disabled,
  showOptions,
  setShowOptions,
}) => (
  <SearchInput
    id="search-form-input"
    value={value}
    onChange={(v) => {
      onChange(v);
      setShowOptions(true);
    }}
    disabled={disabled}
    placeholder="Find your course or a unit"
    inputLabel="Search for your course or unit"
    onBlur={(ref, e) => {
      if (ref.current && !ref.current.contains(e.relatedTarget)) {
        setShowOptions(false);
      }
    }}
  >
    {showOptions && (
      <AutoCompleteList
        value={value}
        hideOptions={() => setShowOptions(false)}
      />
    )}
    <SearchButton onClick={onSubmit} disabled={disabled} label="search" />
  </SearchInput>
);

PrimarySearch.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  showOptions: PropTypes.bool.isRequired,
  setShowOptions: PropTypes.func.isRequired,
};

PrimarySearch.defaultProps = {
  onSubmit: () => null,
  disabled: false,
};

export default PrimarySearch;
