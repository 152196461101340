import { useContext, useEffect, useReducer, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { API } from 'aws-amplify';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { searchCourseOrUnit as courseOrUnitQuery } from '../../../graphql/queries';
import { StudyModeContext } from '../../StudyModeContext';
import SearchPage from './SearchPage';
import SearchHeader from './SearchHeader';
import SideBar from './SideBar';
import Results from './Results';
import {
  halfScrollOverride,
  initSearchState,
  SET_ERROR,
  SET_LOADING,
  SET_RESULTS,
  SET_SEARCH_TERM,
} from './constants';
import searchReducer from './searchReducer';
import hasError from '../../Utils/hasError';

const getParams = (search) => {
  const params = new URLSearchParams(search);
  const studyTypeQuery = params.get('study_type');
  return {
    pageQuery: params.get('page') || '1',
    studyTypeQuery: ['all', 'course', 'unit'].includes(studyTypeQuery)
      ? studyTypeQuery
      : 'all',
    searchValueQuery: params.get('search_text') || '',
    studyLevelQuery: params.get('study_level') || null,
    fieldOfEducationQuery: params.get('field_of_education') || null,
    locationQuery: params.get('location') || null,
    attendanceModeQuery: params.get('attendance_mode') || null,
  };
};

const resolveStudyTypeQuery = (studyTypes) => {
  switch (studyTypes) {
    case 'all':
      return ['COURSE', 'UNIT'];
    case 'course':
      return ['COURSE'];
    case 'unit':
      return ['UNIT'];
    default:
      return ['COURSE', 'UNIT'];
  }
};

const searchAPI = async (
  variables,
  dispatch,
  resetPage,
  trackException,
  signal
) => {
  dispatch({ type: SET_LOADING });
  try {
    const {
      data: { searchCourseOrUnit },
    } = await API.graphql({
      query: courseOrUnitQuery,
      variables,
      authMode: 'AWS_IAM',
    });
    if (signal.aborted) {
      return;
    }
    dispatch({ ...searchCourseOrUnit, type: SET_RESULTS });
  } catch (error) {
    const { errors } = error;
    if (hasError(errors, 'MAXIMUM_PAGE_LIMIT_EXCEEDED')) {
      resetPage();
      return;
    }
    console.error(error);
    trackException(
      new Error(
        `Query failed with errors: ${errors
          .map(({ message }) => message)
          .join(',')}`
      )
    );
    if (signal.aborted) {
      return;
    }
    dispatch({ type: SET_ERROR });
  }
};

const Home = () => {
  const { push, replace } = useHistory();
  const { search } = useLocation();
  const { shortcode } = useContext(StudyModeContext);
  const [
    {
      totalMatches,
      results,
      suggestion,
      filters,
      isLoading,
      searchTerm,
      isError,
    },
    dispatch,
  ] = useReducer(searchReducer, initSearchState);
  const setSearchTerm = (value) => dispatch({ type: SET_SEARCH_TERM, value });
  const appInsights = useAppInsightsContext();

  const searchState = getParams(search);
  const previousPage = useRef();

  const filterValues = {
    studyLevel: searchState.studyLevelQuery,
    fieldOfEducation: searchState.fieldOfEducationQuery,
    location: searchState.locationQuery,
    attendanceMode: searchState.attendanceModeQuery,
  };

  useEffect(() => {
    const {
      pageQuery,
      studyTypeQuery,
      searchValueQuery,
      studyLevelQuery,
      locationQuery,
      fieldOfEducationQuery,
      attendanceModeQuery,
    } = getParams(search);
    const pageChanged =
      previousPage.current && pageQuery !== previousPage.current;

    // Page changes should scroll to top of search content
    if (pageChanged) {
      const element = document.getElementById('offering-search');
      element.scrollIntoView({ behavior: 'smooth' });
    }
    // Make sure the search state is always in sync with
    // the query parameter
    setSearchTerm(searchValueQuery);

    const abortController = new AbortController();

    searchAPI(
      {
        page: parseInt(pageQuery, 10),
        studyTypes: resolveStudyTypeQuery(studyTypeQuery),
        studyMode: shortcode,
        phrase: searchValueQuery,
        studyLevel: studyLevelQuery,
        locationCode: locationQuery,
        fieldOfEducationCode: fieldOfEducationQuery,
        attendanceModeCode: attendanceModeQuery,
      },
      dispatch,
      () => {
        const params = new URLSearchParams(search);
        params.set('page', 1);
        replace({ pathname: '/', search: params.toString() });
      },
      (exception) => {
        if (appInsights) {
          appInsights.trackException({ exception });
        }
      },
      abortController.signal
    );

    // Update previous page ref
    previousPage.current = pageQuery;
    return () => abortController.abort();
  }, [search, shortcode, replace, appInsights]);

  const updateQueryParam = (key, value) => {
    const params = new URLSearchParams(search);
    if (key) {
      if (value === null) {
        params.delete(key);
      } else {
        params.set(key, value);
      }
    }
    params.set('page', 1);
    params.set('search_text', searchTerm);
    push({
      pathname: '/',
      search: params.toString(),
      state: !key || key === 'study_type' ? halfScrollOverride : {},
    });
  };

  const resetFilters = () => {
    const params = new URLSearchParams(search);
    params.delete('study_level');
    params.delete('field_of_education');
    params.delete('location');
    params.delete('attendance_mode');
    params.set('page', 1);
    params.set('search_text', searchTerm);
    push({ pathname: '/', search: params.toString() });
  };

  return (
    <>
      <SearchHeader
        searchValue={searchTerm}
        onSearchValueChange={setSearchTerm}
        onSearchSubmit={() => updateQueryParam()}
        studyTypes={searchState.studyTypeQuery}
        onStudyTypesChange={(value) => updateQueryParam('study_type', value)}
        disabled={isLoading}
        suggestion={suggestion}
      />
      <SearchPage loading={isLoading}>
        <SideBar
          filters={filters}
          filterValues={filterValues}
          onFilterChange={updateQueryParam}
          onResetFilters={resetFilters}
          isLoading={isLoading}
        />
        <Results
          total={totalMatches}
          results={results}
          searchState={searchState}
          isLoading={isLoading}
          isError={isError}
          filterCount={Object.values(filterValues).filter((v) => !!v).length}
        />
      </SearchPage>
    </>
  );
};

export default Home;
