import { useEffect, useContext, useState } from 'react';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import { API } from 'aws-amplify';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { searchUnitDetails as unitDetailsQuery } from '../../../graphql/queries';
import Disclaimer from '../../Disclaimer';
import {
  Page,
  Primary,
  Header,
  Subheader,
  Card,
  CardContent,
  CardGroup,
  PreWrap,
} from '../../Common';
import SelectVersion from '../../SelectVersion';
import Location from '../../Location';
import { StudyModeContext } from '../../StudyModeContext';
import { LoadingMessage, ErrorMessage } from '../../Message';
import { versionSortOrder, parseVersion } from '../../Utils/version';
import SideBar from '../../SideBar';
import Summary from '../../SideBar/Summary';
import MobileCard from '../../SideBar/MobileCard';
import SideBarLinks from '../../SideBarLinks';
import UnitDetails from '../../SideBar/UnitDetails';
import Syllabus from './Syllabus';
import TuitionPatterns from './TuitionPatterns';
import FurtherInfo from './FurtherInfo';
import { PageNameContext } from '../../BreadCrumbs/PageNameProvider';
import { NotifierContext } from '../../Notifier/NotifierProvider';
import Requisites from './Requisites';
import hasError from '../../Utils/hasError';
import useIsMobile from '../../Utils/isMobile';

const initialState = {
  isLoading: true,
  isError: false,
  isRedirect: false,
  unitDetails: {},
  unitVersions: [],
  selectedUnitVersion: {},
};

const UnitDetail = () => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const { seoFriendlyUrl } = useParams();
  const { shortcode } = useContext(StudyModeContext);
  const { setPageName } = useContext(PageNameContext);
  const { setMessage } = useContext(NotifierContext);
  const appInsights = useAppInsightsContext();

  const [
    {
      isLoading,
      isError,
      isRedirect,
      unitDetails,
      unitVersions,
      selectedUnitVersion,
    },
    setDetailsState,
  ] = useState(initialState);

  useEffect(() => {
    const searchUnitDetailsById = async (
      seoFriendlyUrlSuffix,
      studyMode,
      signal
    ) => {
      setDetailsState(initialState);
      setMessage('Loading unit details');

      try {
        const {
          data: { searchUnitDetails },
        } = await API.graphql({
          query: unitDetailsQuery,
          variables: { seoFriendlyUrlSuffix, studyMode },
          authMode: 'AWS_IAM',
        });
        if (signal.aborted) {
          return;
        }

        setPageName(searchUnitDetails.unitDetails.longTitle);
        const versionDetails = searchUnitDetails.unitVersions
          .map(parseVersion)
          .sort(
            (versionA, versionB) =>
              versionSortOrder.indexOf(versionA.tense) -
              versionSortOrder.indexOf(versionB.tense)
          );
        const selectedVersion = versionDetails
          .filter(({ value }) => value === seoFriendlyUrl)
          .slice(-1)[0];
        setDetailsState({
          ...initialState,
          isLoading: false,
          unitDetails: {
            ...searchUnitDetails.unitDetails,
            requisites: searchUnitDetails.unitDetails.requisites.map(
              (requisite) => ({
                ...requisite,
                rule: JSON.parse(requisite.rule),
              })
            ),
          },
          unitVersions: versionDetails,
          selectedUnitVersion: selectedVersion,
        });
        setMessage(
          `Unit details for ${searchUnitDetails.unitDetails.longTitle} has finished loading`
        );
      } catch (error) {
        if (signal.aborted) {
          return;
        }
        setMessage('There was an error displaying information for this unit');
        handleError(error, (exception) => {
          if (appInsights) {
            appInsights.trackException({ exception });
          }
        });
      }
    };

    const abortController = new AbortController();
    searchUnitDetailsById(seoFriendlyUrl, shortcode, abortController.signal);
    return () => abortController.abort();
  }, [seoFriendlyUrl, shortcode, setPageName, setMessage, appInsights]);

  const handleError = (error, trackException) => {
    const { errors } = error;
    if (hasError(errors, 'MIN_ENTRIES')) {
      setDetailsState({
        ...initialState,
        isRedirect: true,
      });
      return;
    }
    console.error(error);
    trackException(
      new Error(
        `Query failed with errors: ${errors
          .map(({ message }) => message)
          .join(',')}`
      )
    );
    setDetailsState({ ...initialState, isError: true });
  };

  if (isRedirect) {
    return <Redirect to="/" />;
  }

  if (isError) {
    return <ErrorMessage />;
  }

  if (isLoading) {
    // TODO: replace with something like
    // https://github.com/danilowoz/react-content-loader
    return <LoadingMessage />;
  }

  const summaryOptions = [
    ['Area', [unitDetails.teachingOrg], true],
    ['Field of Education', [unitDetails.fieldOfEducation], true],
    ['Credits', [unitDetails.credits], true],
    ['Contact hours', [unitDetails.contactHours], true],
    ['Result type', [unitDetails.resultType], true],
  ];

  const sideBarOptions = {
    title: unitDetails.longTitle,
    studyArea: unitDetails.studyArea,
    seoFriendlyUrl,
  };

  return (
    <>
      <PreWrap>
        <Page>
          <Primary>
            <Header title={unitDetails.longTitle} />
            <Subheader
              primary={unitDetails.udc}
              secondary={selectedUnitVersion}
              disclaimer="This version is currently on the planned status and subject to change,
              students are suggested to check this version again in the future."
            />
            {unitVersions.length > 1 && (
              <SelectVersion
                options={unitVersions}
                value={{
                  ...selectedUnitVersion,
                  label: 'Other versions of this course',
                }}
                onChange={(value) => {
                  history.push(`/units/${value.value}`);
                }}
              />
            )}
            <MobileCard summaryOptions={summaryOptions} />
            <CardGroup>
              <Card>
                <CardContent>
                  <Summary summaryOptions={summaryOptions} />
                </CardContent>
              </Card>
            </CardGroup>
            <Syllabus content={unitDetails.syllabusContent} />
            <TuitionPatterns tuitionPatterns={unitDetails.tuitionPatterns} />
            <Requisites requisites={unitDetails.requisites} />
            {unitDetails.furtherInformation && (
              <FurtherInfo content={unitDetails.furtherInformation} />
            )}
            <h2 className="loc-delivery-header">Location and delivery</h2>
            {unitDetails.locationsAndDelivery.length > 0 && (
              <>
                <p>
                  For start dates, please view the&nbsp;
                  <a
                    href="https://students.curtin.edu.au/essentials/academic-calendar/"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="academic calendar"
                  >
                    academic calendar
                  </a>
                  .
                </p>
                <Location options={unitDetails.locationsAndDelivery} />
                <p>
                  Partially Online Internal refers to some (a portion of)
                  learning provided by interacting with or downloading
                  pre-packaged material from the Internet but with regular and
                  ongoing participation with a face-to-face component retained.
                  Excludes partially online internal course/units run through
                  the Curtin Bentley-based Distance Education Area which remain
                  Central External
                </p>
                <p>
                  Fully Online refers to the main (larger portion of) mode of
                  learning provided via Internet interaction (including the
                  downloading of pre-packaged material on the Internet).
                  Excludes online course/units run through the Curtin
                  Bentley-based Distance Education Area which remain Central
                  External
                </p>
              </>
            )}
          </Primary>
          <SideBar
            sideBarOptions={sideBarOptions}
            summaryOptions={summaryOptions}
            type="unit"
          />
        </Page>
        {isMobile && <UnitDetails isMobile={isMobile} />}
        <Disclaimer />
        {isMobile && <SideBarLinks isMobile={isMobile} />}
      </PreWrap>
    </>
  );
};

export default UnitDetail;
