import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import { resultProps } from './propTypes';
import Pagination from './Pagination';
import NoResults from './NoResults';
import StudyModeNotification from './StudyModeNotification';
import Result from './Result';
import TotalResults from './ResultsHeader';
import ErrorMessage from './ErrorMessage';

import './Results.scss';

const ResultsColumn = ({ children, total, filterCount }) => (
  <div className="search__content" aria-live="polite">
    <section className="search-results">
      <TotalResults value={total} filterCount={filterCount} />
      <StudyModeNotification />
      {children}
    </section>
  </div>
);

ResultsColumn.propTypes = {
  children: PropTypes.node.isRequired,
  total: PropTypes.number.isRequired,
  filterCount: PropTypes.number.isRequired,
};

const resolveChildren = (
  total,
  results,
  searchState,
  isLoading,
  isError,
  search
) => {
  if (isError) {
    return (
      <div className="search-results__card-container">
        <ErrorMessage />
      </div>
    );
  }

  if (total === 0 && !isLoading && !isError) {
    return <NoResults searchValue={searchState.searchValueQuery} />;
  }

  return (
    <>
      <Link to={{ hash: '#search-form-input', search }} className="sr-jump">
        Skip to search text
      </Link>
      <div className="search-results__card-container">
        {results.map(({ id, url, title, category, description }, index) => (
          <Result
            key={url}
            index={index}
            id={id}
            url={url}
            title={title}
            category={category}
            description={description}
          />
        ))}
      </div>
      <Pagination total={total} searchState={searchState} />
    </>
  );
};

const Results = ({
  total,
  results,
  searchState,
  isLoading,
  isError,
  filterCount,
}) => {
  const { search } = useLocation();
  return (
    <ResultsColumn total={total} filterCount={filterCount}>
      {resolveChildren(total, results, searchState, isLoading, isError, search)}
    </ResultsColumn>
  );
};

Results.propTypes = {
  total: PropTypes.number.isRequired,
  results: PropTypes.arrayOf(PropTypes.shape(resultProps)).isRequired,
  searchState: PropTypes.shape({
    pageQuery: PropTypes.string.isRequired,
    searchValueQuery: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  filterCount: PropTypes.number.isRequired,
};

export default Results;
