import { SET_TAB_OPEN, SWAP_ALL_EXPANDED } from './constants';

const setTabOpen = (state, action) => {
  const { index, value } = action;
  const stringIndex = index.toString();

  // Expand all items prefixed with year level index
  // and set override to true for expand all behavior
  if (!stringIndex.includes('.') && value.open) {
    const yearLevelState = Object.fromEntries(
      Object.keys(state)
        .filter((key) => key.startsWith(stringIndex))
        .map((key) => [key, { open: true, override: true }])
    );
    return { ...state, ...yearLevelState };
  }

  return { ...state, [index]: value };
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_TAB_OPEN:
      return setTabOpen(state, action);
    case SWAP_ALL_EXPANDED:
      return Object.fromEntries(
        Object.keys(state).map((key) => [
          key,
          { open: action.value, override: true },
        ])
      );
    default:
      return state;
  }
};

const getInitialState = (structure) =>
  structure
    .filter(
      ({ yearLevel, yearLevelEntries }) =>
        !(yearLevel === 'NA' && yearLevelEntries.length === 0)
    )
    .map(({ yearLevelEntries }, yearIndex) => [
      `${yearIndex}`,
      ...yearLevelEntries
        .map(({ studyPeriod, studyPeriodEntries }, studyPeriodIndex) =>
          (studyPeriodEntries === null && studyPeriod === 'NA') ||
          (studyPeriodEntries.electiveGroups.core.length === 0 &&
            studyPeriodEntries.electiveGroups.options.length === 0)
            ? [`${yearIndex}.${studyPeriodIndex}`]
            : [
                `${yearIndex}.${studyPeriodIndex}`,
                `${yearIndex}.${studyPeriodIndex}.options`,
                `${yearIndex}.${studyPeriodIndex}.alternatecore`,
              ]
        )
        .flat(),
    ])
    .flat()
    .reduce(
      (acc, key) => ({ ...acc, [key]: { open: false, override: false } }),
      {}
    );

export { reducer, getInitialState };
